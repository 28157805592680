import {
  PrivilegeArray,
  MultiSelect,
  Lookup,
  LookupList,
  Text,
  Map,
  StoryImage,
  StoryVersionHistory,
} from './types';
import api from '../../utils/api-axios';
import store from '../../store';
import toFormData from '../../utils/to-form-data';
import deepEquals from '../../utils/deepEquals';

export default {
  roles: {
    name: 'Role',
    title: 'Roles',
    apiUrl: '/cms-users-service/roles',
    table: {
      name: {
        label: 'Name',
      },
      description: {
        label: 'Description',
      },
    },
    identifierKey: 'name',
    form: {
      fields: {
        name: {
          label: 'Name',
          type: String,
        },
        description: {
          label: 'Description',
          type: String,
        },
        privileges: {
          label: 'Privileges',
          type: PrivilegeArray,
        },
      },
    },
  },
  users: {
    name: 'User',
    title: 'Users',
    apiUrl: '/cms-users-service/users',
    allowSearch: false,
    table: {
      email: {
        label: 'Email',
      },
      name: {
        label: 'Name',
        resolver: user =>
          user.firstName && user.lastName
            ? user.firstName + ' ' + user.lastName
            : '[Awaiting initial login]',
      },
      roles: {
        label: 'Roles',
        transformer: roles => roles.map(role => role.name).join(', '),
      },
    },
    identifierKey: 'email',
    form: {
      fields: {
        email: {
          label: 'Email',
          type: String,
        },
        firstName: {
          label: 'First Name',
          type: String,
          disabled: true,
        },
        lastName: {
          label: 'Last Name',
          type: String,
          disabled: true,
        },
        roles: {
          label: 'Roles',
          type: MultiSelect,
          options: () => {
            return api
              .get('/cms-users-service/roles')
              .then(res => res.data.filter(role => role.name !== 'Public'));
          },
          itemText: 'name',
          itemValue: 'name',
        },
      },
    },
    overrides: {
      createPage: {
        form: {
          fields: {
            email: {
              label: 'Email',
              type: String,
            },
            roles: {
              label: 'Roles',
              type: MultiSelect,
              options: () => {
                return api
                  .get('/cms-users-service/roles')
                  .then(res => res.data.filter(role => role.name !== 'Public'));
              },
              itemText: 'name',
              itemValue: 'name',
            },
          },
        },
      },
    },
  },
  stories: {
    name: 'Story',
    title: 'Stories',
    apiUrl: '/stories-service/stories',
    allowSearch: true,
    paramOptions: {
      isEnabled: {
        label: 'Hide disabled',
        sendFalse: false,
        default: true,
      },
    },
    identifierKey: 'id',
    labelKey: 'name',
    tableOptions: {
      sortBy: 'storyNumber',
      sortDesc: true,
    },
    table: {
      storyNumber: {
        label: 'Story',
      },
      status: {
        label: 'Status',
        resolver: story => {
          if (!story.isEnabled) {
            return 'Disabled';
          } else if (new Date(story.publishDate) > new Date()) {
            return 'Scheduled';
          } else {
            return 'Published';
          }
        },
      },
      name: {
        label: 'Name',
      },
      country: {
        label: 'Country',
        transformer: country => (country || {}).value,
      },
      series: {
        label: 'Series',
        transformer: series => (series || {}).value,
      },
      categories: {
        label: 'Categories',
        transformer: categories =>
          (categories || []).map(category => (category || {}).value).join(', '),
      },
      livsmestring: {
        label: 'Livsmestring',
        resolver: story => {
          if (story.livsmestringIsEnabled) {
            return 'Yes';
          } else {
            return 'No';
          }
        },
      },
      publishDate: {
        label: 'Publish date',
        transformer: date => new Date(date).toUTCString(),
      },
    },
    formData: true,
    transformSubmitRequest(data) {
      data.authorEmail = store.state.user.email;
      if (!data.livsmestringIsEnabled) {
        data.livsmestringDescription = '';
        data.livsmestringCaseStudy = '';
        data.livsmestringQuestions = '';
        data.livsmestringDownloadUrl = '';
        data.livsmestringSlidesUrl = '';
      }
      return data;
    },
    updateHandler(story, { previous }) {
      const metadataKeys = [
        'authorEmail',
        'storyNumber',
        'priority',
        'publishDate',
        'isEnabled',
      ];

      const onlyMetadataChanged = deepEquals(story, previous, {
        excludeKeys: metadataKeys,
      });

      if (onlyMetadataChanged) {
        const metadata = {
          storyNumber: story.storyNumber,
          priority: story.priority,
          publishDate: story.publishDate,
          isEnabled: story.isEnabled,
        };
        return api.put(
          `/stories-service/stories/${story.id}/metadata`,
          metadata,
        );
      } else {
        return api.put(
          `/stories-service/stories/${story.id}`,
          toFormData(story), // Form data is used in order to send the images
        );
      }
    },
    form: [
      {
        cols: 8,
        tabNames: ['Story', 'Livsmestring'],
        fields: [
          {
            imageFile: {
              label: 'Choose cover image',
              type: StoryImage,
            },
            name: {
              label: 'Name',
              type: String,
            },
            country: {
              label: 'Country',
              type: Lookup,
              lookupUrl: '/stories-service/countries',
            },
            series: {
              label: 'Series',
              type: Lookup,
              lookupUrl: '/stories-service/series',
            },
            categories: {
              label: 'Categories',
              type: LookupList,
              lookupUrl: '/stories-service/categories',
            },
            subcategories: {
              label: 'Subcategories',
              type: LookupList,
              lookupUrl: '/stories-service/subcategories',
            },
            description: {
              label: 'Description',
              type: Text,
            },
            vimeoUrl: {
              label: 'Vimeo URL',
              type: Map,
              keys: ['preview', 'full'],
            },
          },
          {
            livsmestringIsEnabled: {
              label: 'Livsmestring enabled',
              falseLabel: 'Livsmestring disabled',
              type: Boolean,
              transformBeforeMounted(id) {
                return !!id;
              },
            },
            livsmestringDescription: {
              label: 'Beskrivelse',
              type: Text,
            },
            livsmestringCaseStudy: {
              label: 'Case study',
              type: Text,
            },
            livsmestringQuestions: {
              label: 'Refleksjonsspørsmål',
              type: Text,
            },
            livsmestringUtdanningsprogram: {
              label: 'Utdanningsprogram',
              type: Lookup,
              lookupUrl: '/stories-service/livsmestring/utdanningsprogram',
            },
            livsmestringKlassetrinn: {
              label: 'Klassetrinn',
              type: Lookup,
              lookupUrl: '/stories-service/livsmestring/klassetrinn',
            },
            livsmestringFag: {
              label: 'Fag',
              type: Lookup,
              lookupUrl: '/stories-service/livsmestring/fag',
            },
            livsmestringKompetansemaal: {
              label: 'Kompetansemål',
              type: LookupList,
              lookupUrl: '/stories-service/livsmestring/kompetansemaal',
            },
            livsmestringDownloadUrl: {
              label: 'Download URL',
              type: String,
            },
            livsmestringSlidesUrl: {
              label: 'Slides URL',
              type: String,
            },
          },
        ],
      },
      {
        cols: 4,
        fields: {
          storyNumber: {
            label: 'Story number',
            type: Number,
          },
          priority: {
            label: 'Priority',
            type: Number,
          },
          publishDate: {
            label: 'Publish date',
            type: Date,
          },
          isEnabled: {
            label: 'Enabled',
            falseLabel: 'Disabled',
            type: Boolean,
          },
          versionHistory: {
            label: 'Version history',
            type: StoryVersionHistory,
          },
        },
      },
    ],
  },
  services: {
    name: 'Service',
    title: 'Services',
    apiUrl: '/hjelpesiden-service/services',
    allowSearch: false, // TO-DO: Add search endpoint in the api
    identifierKey: 'id',
    labelKey: 'name',
    table: {
      id: {
        label: 'Id',
      },
      name: {
        label: 'Name',
      },
      websiteUrl: {
        label: 'Website',
      },
      country: {
        label: 'Country',
        transformer: country => (country || {}).value,
      },
      categories: {
        label: 'Categories',
        transformer: categories =>
          (categories || []).map(category => (category || {}).value).join(', '),
      },
      status: {
        label: 'Priority',
        resolver: service => {
          if (!service.priority) {
            return 'False';
          } else {
            return 'True';
          }
        },
      },
      emergency: {
        label: 'Emergency',
        resolver: service => {
          if (!service.emergency) {
            return 'False';
          } else {
            return 'True';
          }
        },
      },
    },
    transformSubmitRequest(data) {
      delete data['updatedAt'];
      delete data['createdAt'];
      return data;
    },
    form: [
      {
        fields: {
          name: {
            label: 'Name',
            type: String,
          },
          websiteUrl: {
            label: 'Website URL',
            type: String,
          },
          websiteChatUrl: {
            label: 'Website chat URL',
            type: String,
          },
          phoneNumber: {
            label: 'Phone number',
            type: String,
          },
          description: {
            label: 'Description',
            type: Text,
          },
          country: {
            label: 'Country',
            type: Lookup,
            lookupUrl: '/hjelpesiden-service/countries',
          },
          categories: {
            label: 'Categories',
            type: LookupList,
            lookupUrl: '/hjelpesiden-service/categories',
          },
          priority: {
            label: 'Priority',
            falseLabel: 'Not priority',
            type: Boolean,
          },
          emergency: {
            label: 'Emergency',
            falseLabel: 'Not emergency',
            type: Boolean,
          },
        },
      },
    ],
  },
};
