export default function toFormData(data) {
  const fd = new FormData();

  for (let key of Object.keys(data)) {
    if (data[key] instanceof File) {
      fd.append(key, data[key]);
    } else {
      fd.append(key, JSON.stringify(data[key]));
    }
  }

  return fd;
}
