import deepEqual from 'deep-equal';

function deepEquals(a, b, { excludeKeys, normalizeUnset } = {}) {
  if (a === b) return true;
  if (typeof a !== typeof b) return false;
  if (typeof a !== 'object') return a === b;

  const aCopy = { ...a };
  const bCopy = { ...b };

  if (normalizeUnset) {
    deleteNullAndUndefined(aCopy);
    deleteNullAndUndefined(bCopy);
  }

  if (excludeKeys) {
    deleteExcludedKeys(aCopy, excludeKeys);
    deleteExcludedKeys(bCopy, excludeKeys);
  }

  return deepEqual(aCopy, bCopy);
}
function deleteNullAndUndefined(obj) {
  Object.keys(obj).forEach(key => {
    if (null === obj[key] || undefined === obj[key]) {
      delete obj[key];
    }
  });
}
function deleteExcludedKeys(obj, excludeKeys) {
  for (let key of excludeKeys) {
    delete obj[key];
  }
}

export default deepEquals;
